import * as React from 'react'
import Seo from '../components/seo'
import { BaseLayout } from '../layouts'
import styled from "styled-components"

const CenteredText = styled.div`
  text-align: center;
`

const ContactPage = () => {
    return (
        <BaseLayout pageTitle={ "Contact" }>
            <CenteredText>
                For inquiries about my work, you can reach me at abc@example.com
            </CenteredText>
        </BaseLayout>
    )
}

export const Head = () => <Seo title="Contact" />

export default ContactPage